import React from "react"
import {graphql} from "gatsby"
import MainLayout from "../components/Layout/layout"

const ErrorPage = ({location}) => {

  return (
    <MainLayout location={location}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center py-5">
            <h1 className="bracket-text">404 404 404</h1>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export const query = graphql`
  query errorPageQuery {
    page: wordpressPage(slug: { eq: "404" }) {
      title
      slug
    }
  }
`

export default ErrorPage
